<template>
  <DashboardCompareStat dialog-name="Compare Stats" />
</template>
<script>
import DashboardCompareStat from '@/views/app/dashboard/CompareStat'

export default {
  name: 'DashboardCompareStatWrapper',
  components: {
    DashboardCompareStat
  }
}
</script>

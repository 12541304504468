<template>  
 <o-container ref="container">
    <v-card elevation="2" shaped class=" fill-height">
      <v-card-title>
        <v-row class="ma-0 pa-1">
          <v-col cols="12" xs="12" class="ma-0 pa-1">
              <v-card-title  class="d-flex justify-space-between">
                <div class="text-uppercase pl-4 mr-auto ">
                {{ title }}
                </div>
                <div class="d-flex justify-end ml-auto pr-0 pb-2">
                    
                </div>
              </v-card-title>
          </v-col>
          <v-col cols="12"
            xs="12"
            sm="9"
            md="9" 
            lg="9"
            xl="9"
            class="ma-0 pa-1">
              <v-card elevation="2"> 
              <v-card-title class="d-flex justify-space-between ">
                <div class="mb-0 mr-4">
                  <p class="card-title text-capitalize mb-0">
                    Chart
                  </p>
                </div>  
              </v-card-title>
              <v-card-text class="ma-0 pa-0">
                <apexchart
                  ref="chartConfig" 
                  type="line"
                  :height="350"
                  :options="chartConfig.chartOptions"
                  :series="chartConfig.series"
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12"
            xs="12"
            sm="3"
            md="3" 
            lg="3"
            xl="3"
            class="ma-0 pa-1">
            <v-card elevation="2"> 
              <v-card-title class="d-flex justify-space-between ">
                <div class="mb-0 mr-4">
                  <p class="card-title text-capitalize mb-0">
                    Selection
                  </p>
                </div>  
              </v-card-title>
              <v-card-text class="ma-0 pa-0 row">
                <v-row class="pa-0 ma-0">
                  <v-col
                    cols="6"
                    class="pa-2 ma-0"
                  >          
                    <o-combo-box
                      :busy="true"       
                      label="Chart Type" 
                      :items="[
                        { id: 'hour', name: 'Hour' },
                        { id: 'day', name: 'Day' },
                      ]"
                      v-model="chartType"
                      :hasId="false"
                    ></o-combo-box>
                  </v-col>
                  <v-col
                    cols="6"
                    class="pa-2 ma-2"
                    style="margin-left:-10px !important;"
                  >
                    <o-check-box
                      v-model="useTextBox"
                      :busy="busy"
                      label="Use Text"
                    />
                  </v-col>
                </v-row>
                <v-row v-if="!useTextBox" class="pa-0 ma-0">
                  <v-col cols="12" class="pa-2 ma-0">
                    <o-combo-box
                      v-model="account_id"
                      :busy="busy"
                      label="Account"
                      :items="accounts"
                      @on-change="getAdvertisers()"
                    />
                  </v-col>
                  <v-col cols="12" class="pa-2 ma-0">
                    <o-combo-box
                      v-model="advertiser_id"
                      :busy="busy"
                      label="Advertisers"
                      :items="advertisers"
                      @on-change="getCampaigns()"
                    />
                  </v-col>
                  <v-col cols="12" class="pa-2 ma-0">
                    <o-combo-box
                      v-model="campaign_id"
                      :busy="busy"
                      label="Campaigns"
                      :items="campaigns"
                      @on-change="getLineItems()"
                    />
                  </v-col>
                  <v-col cols="12" class="pa-2 ma-0">
                    <o-combo-box
                      v-model="line_item_id"
                      :busy="busy"
                      label="Line Items"
                      :items="line_items"
                    />
                  </v-col>
                </v-row>
                <v-row v-if="useTextBox" class="pa-0 ma-0">
                  <v-col cols="12" class="pa-2 ma-0">
                    <o-input
                      v-model="account_id"
                      :busy="busy"
                      label="Account"
                      :items="accounts"
                    />
                  </v-col>
                  <v-col cols="12" class="pa-2 ma-0">
                    <o-input
                      v-model="advertiser_id"
                      :busy="busy"
                      label="Advertisers"
                      :items="advertisers"
                    />
                  </v-col>
                  <v-col cols="12" class="pa-2 ma-0">
                    <o-input
                      v-model="campaign_id"
                      :busy="busy"
                      label="Campaigns"
                      :items="campaigns"
                    />
                  </v-col>
                  <v-col cols="12" class="pa-2 ma-0">
                    <o-input
                      v-model="line_item_id"
                      :busy="busy"
                      label="Line Items"
                      :items="line_items"
                    />
                  </v-col>
                </v-row>
                <v-row class="pa-0 ma-0">             
                  <v-col
                    cols="12"
                    class="pa-2 ma-0"
                    style="margin-left:-10px !important"
                  >
                    <o-date-range v-model="dates" />
                  </v-col>    
                </v-row>
                <v-row class="pa-3 ma-0">
                  <o-button
                    :busy="busy"
                    color="success"
                    icon="mdi-plus"
                    type="right-label-icon"
                    label="Add to Chart"
                    @on-click="addData"
                  />
                  <o-button
                    :busy="busy"
                    color="primary"
                    icon="mdi-sync"
                    type="label-icon"
                    class="ml-2"
                    label="Refresh"
                    @on-click="reset()"
                  />
                </v-row>
              </v-card-text>
            </v-card>        
          </v-col> 
        </v-row>  
    </v-card-title>
    </v-card>
 </o-container>
</template>
<script> 
import RestApi from '@/services/RestApi' 
import { mapGetters } from "vuex";

export default {
  name: 'DashboardCompareStat',
  mixins: [RestApi],
  metaInfo: {
    title: 'Compare Stats',
  },  
  data() {
    return {
      title: 'Compare Stats',
      busy: false,
      useTextBox: false,
      accounts: [],
      advertisers: [],
      campaigns: [],
      line_items: [],
      data: [],
      account_id: 5,
      advertiser_id: 0,      
      campaign_id: 0,
      line_item_id: 0, 
      chartType: 'hour',
      accounts: [],
      dates: [],
      chartConfig: { chartOptions: {}, series: [] },
    }
  },
  created() {
    const date = new Date((new Date()).getTime() - 60000);    

    const defaultConfig =  _.cloneDeep(this.lineChartConfig);    
    defaultConfig.series = [];
    /*defaultConfig.chartOptions.animations = {
      enabled: true,
      easing: 'linear',
      dynamicAnimation: {
        speed: 1000
      }
    };
    defaultConfig.chartOptions.legend = { show: true };
    defaultConfig.chartOptions.markers = {
      size: 0,
      style: 'hollow',
    }*/
    defaultConfig.chartOptions.xaxis = {
      type: 'datetime', 
      tickAmount: 6
    }
    defaultConfig.chartOptions.tooltip = {
      x: {
        format: 'dd MMM yyyy HH:00',
      },
    }
    this.chartConfig = _.cloneDeep(defaultConfig);
    this.chartConfig.series = [
      /*{
        name: 'Total',
        type: 'line',
        data: [{x: date, y: 0}]
      }, 
      {
        name: 'Active',
        type: 'line',
        data: [{x: date, y: 0}]
      }, 
      {
        name: 'Idle',
        type: 'line',
        data: [{x: date, y: 0}]
      },*/      
    ];
    
  },
  computed: { 
    ...mapGetters([`loggedInUser`]),
  },  
  watch: {  
  }, 
  async mounted() {
    await this.getData(`rest/accounts`, null).then((response) => {
      this.accounts.push({ id: 0, name: "All" });
      response.data.forEach((d) => {
        this.accounts.push({ id: d.id, name: d.account_name });
      });
      this.advertisers.push({ id: 0, name: "All" });
      this.campaigns.push({ id: 0, name: "All" });
      this.line_items.push({ id: 0, name: "All" });
    });
    const date = this.getDate(new Date().toUTCString());
    this.dates = [date, date];
    
  },
  methods: {   
    reset() {
      this.data = [];
      if (this.$refs.chartConfig) { 
        this.$refs.chartConfig.updateOptions({
          xaxis: { categories: []},
          series: [],
        })        
      }   
    },
    async addData() {
      if (this.dates.length > 0) { 
        
        const payload = _.cloneDeep(this.listParameters);
        payload.fields = [
          `SUM(a.impressions) AS impressions`, 
          `SUM(a.clearing_price) AS clearing_price`, 
          `sum(a.spends) as spends`, 
          `sum(a.bids) as bids`, 
          `sum(a.bid_price) as bid_price`, 
          `sum(a.wins) as wins`, 
          `sum(a.starts) as starts`, 
          `sum(a.completes) as completes`
        ];
        payload.sources = {
          main: `traffic_stats_2_months a`,
          children: [],
        };
        payload.groups = []
        payload.wheres = [
          this.dates[1] && this.dates[0] != this.dates[1] ?
           `a.bid_date between '${(this.dates[0])} 00:00:00' and '${(this.dates[1])} 23:59:59'` 
          :`cast(a.bid_date as date) = '${(this.dates[0])}'` 
        ];
        
        if (this.account_id > 0) {
          payload.fields.push(`a.account_id`)
          payload.groups.push(`a.account_id`)
          payload.wheres = [[payload.wheres, [`a.account_id = ${this.account_id}`]].join(" and ")];
        }
        if (this.advertiser_id > 0) {
          payload.fields.push(`a.advertiser_id`)
          payload.groups.push(`a.advertiser_id`)
          payload.wheres = [[payload.wheres, [`a.advertiser_id = ${this.advertiser_id}`]].join(" and ")];
        }
        if (this.campaign_id > 0) {
          payload.fields.push(`a.campaign_id`)
          payload.groups.push(`a.campaign_id`)
          payload.wheres = [[payload.wheres, [`a.campaign_id = ${this.campaign_id}`]].join(" and ")];
        }
        if (this.line_item_id > 0) {
          payload.fields.push(`a.line_item_id`)
          payload.groups.push(`a.line_item_id`)
          payload.wheres = [[payload.wheres, [`a.line_item_id = ${this.line_item_id}`]].join(" and ")];
        }
        if (this.chartType == 'hour') {
          payload.fields.push('a.bid_date')
          payload.groups.push('a.bid_date')
        } else {
          payload.fields.push(`cast(a.bid_date as date) as bid_date`)
          payload.groups.push('cast(a.bid_date as date)')
        }

        const url = `/rest/intranet/list?ts=${(new Date()).getTime()}`;  
        
        await this.postData(url, payload).then((response) => {
         
          const data = {
            params: {
              account_id: this.account_id,
              advertiser_id: this.advertiser_id,
              campaign_id: this.campaign_id,
              line_item_id: this.line_item_id,
            },
            rows: response.data.results
          };
          if (!this.data.includes(data)) {
            this.data.push(data)
          }
        });        
        await this.refreshChart();
      }
      else {
        this.$refs.container.snackBar(
              `Please select valid date range.`,
              `danger`,
              2000
            ); 
      }
    },
    async refreshChart() {
        let categories = [];
        this.data.forEach((d) => {
          d.rows.forEach((e) => {
            if (!categories.includes[e.bid_date]) categories.push(e.bid_date);
          });
        });
        
        categories = categories.sort((a, b)=> (a > b ? 1 : -1))
        const series = this.data.map((d) => {
          const name = []
          if (d.params.account_id > 0) {
            name.push(`${ this.accounts.filter((e) => e.id == d.params.account_id)[0].name} (${d.params.account_id})`)
          }
          if (d.params.advertiser_id > 0) {
            name.push(`Advertiser ID : ${d.params.account_id}`)
          }
          if (d.params.campaign_id > 0) {
            name.push(`Campaign ID : ${d.params.account_id}`)
          }
          if (d.params.line_item_id > 0) {
            name.push(`Line Item ID : ${d.params.account_id}`)
          }
          const data = [];
          categories.forEach((c) => {            
            const tmp = d.rows.filter((f) => f.bid_date == c);
            if (tmp.length > 0) {
              data.push(tmp[0].impressions)
            } else {
              data.push(0);
            }
          })
          return {
            name: (name.length > 0 ? name.join('<br>'): 'All-time'),
            type: 'line',
            data
          }
        })
        let xaxis = {
          type: 'datetime', 
          tickAmount: 6
        }
        let tooltip = {
          x: {
            format: 'dd MMM yyyy HH:00',
          },
        };
        if (this.chartType == 'day') {
          xaxis.type = 'datetime';
          tooltip.x.format = 'dd MMM yyyy';          
        }  
        if (this.$refs.chartConfig) { 
          this.$refs.chartConfig.updateOptions({
            xaxis: {
              categories,
              type: xaxis.type
            },
            tooltip,
            series
          })        
        }             
        /*const date = new Date();        

        const chartConfigSeries = this.chartConfig.series;
         
        if (this.$refs.chartConfig) {          
          chartConfigSeries[0].data = chartConfigSeries[0].data.slice(chartConfigSeries[0].data.length - 50, chartConfigSeries[0].data.length);        
          if (this.$refs.chartConfig) {
            this.$refs.chartConfig.updateSeries(chartConfigSeries)        
          }        
        }*/  
    },
    async getAdvertisers() {
      this.advertisers = [];
      if (this.account_id > 0) {
        this.dropBusy = true;
        this.getData(
          `rest/advertisers?platform_id=1&account_id=${this.account_id}&sort_by=advertiser_name&order=ASC&page_size=5000&compact=1`,
          null
        ).then((response) => {
          this.advertisers.push({ id: 0, name: "All" });
          this.campaigns.push({ id: 0, name: "All" });
          this.line_items.push({ id: 0, name: "All" });
          response.data.results.forEach((d) => {
            this.advertisers.push({
              id: d.advertiser_id,
              name: d.advertiser_name,
            });
          });
          this.dropBusy = false;
        });
      } else {
        this.advertisers = [];
        this.advertisers.push({ id: 0, name: "All" });
        this.campaigns.push({ id: 0, name: "All" });
        this.line_items.push({ id: 0, name: "All" });
      }
    },
    async getCampaigns() {
      this.campaigns = [];
      if (this.advertiser_id > 0) {
        this.dropBusy = true;
        this.getData(
          `rest/campaigns?platform_id=1&account_id=${this.account_id}&advertiser_id=${this.advertiser_id}&sort_by=campaign_name&order=ASC&page_size=500&compact=1`,
          null
        ).then((response) => {
          this.campaigns.push({ id: 0, name: "All" });
          this.line_items.push({ id: 0, name: "All" });
          response.data.results.forEach((d) => {
            this.campaigns.push({ id: d.campaign_id, name: d.campaign_name });
          });
          this.dropBusy = false;
        });
      } else {
        this.campaigns = [];
        this.campaigns.push({ id: 0, name: "All" });
        this.line_items.push({ id: 0, name: "All" });
      }
    },
    async getLineItems() {
      this.line_items = [];
      if (this.advertiser_id > 0) {
        this.dropBusy = true;
        this.getData(
          `rest/line_items?platform_id=1&account_id=${this.account_id}&advertiser_id=${this.advertiser_id}&campaign_id=${this.campaign_id}&sort_by=line_item_name&order=ASC&page_size=500&compact=1`,
          null
        ).then((response) => {
          this.line_items.push({ id: 0, name: "All" });
          response.data.results.forEach((d) => {
            this.line_items.push({
              id: d.line_item_id,
              name: d.line_item_name,
            });
          });
          this.dropBusy = false;
        });
      } else {
        this.line_items = [];
        this.line_items.push({ id: 0, name: "All" });
      }
    },
  }
}
</script>
<style scoped>
  .cursor-pointer {
    cursor: pointer;
  }
</style>